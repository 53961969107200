import React, {Component} from "react";
import {ClockLoader} from "react-spinners";

class Spinner extends Component {

    render () {
        return (
            <div className="loader-container d-flex justify-content-center">
                <ClockLoader color="#6C584C" />
            </div>
        );
    }
}

export default Spinner;

// noinspection JSCheckFunctionSignatures
import React, {Component} from 'react';
import Navbar from './Navbar';
import {Container} from "reactstrap";
import {
    Switch,
    Route, BrowserRouter, Redirect,
} from "react-router-dom";
import Spinner from '../utils/Spinner';
import Breadcrumbs from "./Breadcrumbs";

// page imports
const Homepage = React.lazy(() => import('../pages/homepage/index'));
const FloraAndFauna = React.lazy(() => import('../pages/flora-and-fauna/index'));
const Excursions = React.lazy(() => import('../pages/excursions'));
const Exercise = React.lazy(() => import('../pages/exercise/index'));
const Recreation = React.lazy(() => import('../pages/recreation/index'));
const HouseRules = React.lazy(() => import('../pages/house_rules/index'));
const MessageBoard = React.lazy(() => import('../pages/message-board/index'));

class MainLayout extends Component {

    render () {
        return (
            <div className="main-container">
                <BrowserRouter>
                    <Navbar/>
                    <Breadcrumbs/>
                    <Container>
                        <React.Suspense fallback={<Spinner/>}>
                            <Switch>
                                <Route path="/flora-and-fauna">
                                    <FloraAndFauna/>
                                </Route>
                                <Route path="/excursions">
                                    <Excursions/>
                                </Route>
                                <Route path="/exercise">
                                    <Exercise />
                                </Route>
                                <Route path="/recreation">
                                    <Recreation />
                                </Route>
                                <Route path="/house-rules">
                                    <HouseRules />
                                </Route>
                                <Route path="/message-board">
                                    <MessageBoard />
                                </Route>
                                <Route exact path="/">
                                    <Homepage/>
                                </Route>
                                <Redirect to="/"/>
                            </Switch>
                        </React.Suspense>
                    </Container>
                </BrowserRouter>
            </div>
        );
    }
}

export default MainLayout;

import React, {Component} from 'react';
import {
    Navbar as ReactstrapNavbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    Collapse,
    NavItem,
    NavLink,
    Container,
} from 'reactstrap';
import {withRouter} from 'react-router-dom';

class  Navbar extends Component {

    constructor (props) {
        super(props);

        this.state = {
            isOpen: false
        }

        this.isCancelled = false;

        this.toggle = this.toggle.bind(this);
    }

    toggle () {
        !this.isCancelled && this.setState(prevState => ({isOpen: !prevState.isOpen}));
    }

    componentWillUnmount () {
        this.isCancelled = true;
    }

    render () {
        // noinspection HtmlUnknownTarget
        return (
            <div className="p-2">
                <ReactstrapNavbar color="success" dark expand="lg">
                    <Container fluid>
                        <NavbarBrand className="clickable" onClick={() => this.props.history.push('/')}>
                            <img src="/logo-white512.png" alt="logo" height={50}/></NavbarBrand>
                        <NavbarBrand className="clickable" onClick={() => this.props.history.push('/')}>
                                Guest Book
                            </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <NavLink className="clickable" onClick={() => this.props.history.push('/message-board')}>Message Board</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="clickable" onClick={() => this.props.history.push('/flora-and-fauna')}>Flora and Fauna</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="clickable" onClick={() => this.props.history.push('/excursions')}>Excursions</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="clickable" onClick={() => this.props.history.push('/exercise')}>Exercise</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="clickable" onClick={() => this.props.history.push('/recreation')}>Recreation</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="clickable" onClick={() => this.props.history.push('/house-rules')}>House Rules</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>

                </ReactstrapNavbar>
            </div>
        );
    }
}

export default withRouter(Navbar);

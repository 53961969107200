import React, {Component} from "react";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link, withRouter} from "react-router-dom";

const pathTitles = route => {
     return route.replace(/-/g, ' ',);
};

class Breadcrumbs extends Component {
    constructor (props) {
        super(props);

        this.state = {
            pathArray: this.parsePath()
        };

        this.isCancelled = false;
        this.unlisten = null;

        this.parsePath = this.parsePath.bind(this);
    }

    componentWillUnmount () {
        this.isCancelled = true;
        this.unlisten();
    }

    componentDidMount () {
        this.unlisten = this.props.history.listen(() => {
            const newPath = this.parsePath();

            !this.isCancelled && this.setState({pathArray: newPath});
        });
    }

    parsePath () {
        const parsedPath = this.props.history.location.pathname.split('/').filter(el => el);

        let pathNameSoFar = '';

        return parsedPath.map((pathElement) => {
            pathNameSoFar += '/' + pathElement;

            return {
                name: pathTitles(pathElement),
                path: pathNameSoFar
            }
        });
    }

    render () {
        return (
            <Breadcrumb className="m-2">
                <BreadcrumbItem>
                    <Link to="/">Home</Link>
                </BreadcrumbItem>
                {this.state.pathArray.map((element, index) =>
                    <BreadcrumbItem key={index}>
                        {index === this.state.pathArray.length - 1 ?
                            <span className="title-case">
                                {element.name}
                            </span>
                            :
                            <Link to={element.path} className="title-case">{element.name}</Link>
                        }
                    </BreadcrumbItem>
                )}
            </Breadcrumb>
        );
    }

}

export default withRouter(Breadcrumbs);
